import React, {Component} from 'react';
import {Chart} from 'primereact/chart';
import firebase from '../service/firebase';

export class PieConsultor extends Component {
     
    constructor(){
        super();
        this.state = {
            consultores : [],
            nota1: 0,
            nota2: 0,
            nota3: 0,
            nota4: 0,
            nota5: 0,
            pieData: {
                labels: ['1','2','3','4','5'],
                datasets: [
                    {
                        data: [20, 5, 5, 5, 5],
                        backgroundColor: [
                            "#20d077",
                            "#007be5",
                            "#f9c851",
                            "#ff6400",
                            "#EF6262"
                        ],
                       
                    }]
            },
        }
    }


    componentDidMount(){
        const db = firebase.firestore();
        let consultores = new Set()
        let query = db.collection('session').where('closed', '==', 'S').where('cancelado', '==', 'N');
        let observer = query.onSnapshot(querySnapshot => {
                console.log(`Received query snapshot of size ${querySnapshot.size}`);
                querySnapshot.docChanges().forEach(change => {
                    let satisfacoes = db.collection('satisfacao').where('session', '==', change.doc.id).where('consultor', '==', this.props.nome).where('ordem', '==', '1')
                        .get()
                        .then(s => {
                            s.forEach(sat =>{
                                //console.log(sat.data().resposta)
                                if (sat.data().resposta == '1'){
                                    let state = this.state
                                    state.nota1 = state.nota1 + 1

                                }
                                if (sat.data().resposta == '2'){
                                    let state = this.state
                                    state.nota2 = state.nota2 + 1
                                    
                                }
                                if (sat.data().resposta == '3'){
                                    let state = this.state
                                    state.nota3 = state.nota3 + 1
                                    
                                }
                                if (sat.data().resposta == '4'){
                                    let state = this.state
                                    state.nota4 = state.nota4 + 1
                                    
                                }
                                
                                if (sat.data().resposta == '5'){
                                    let state = this.state
                                    state.nota5 = state.nota5 + 1
                                    
                                }
        
                            })
                        }
                        
                        ).then(a =>{
                            let state = this.state
                            state.pieData = {
                                labels: ['5','4','3','2','1'],
                                datasets: [
                                    {
                                        data: [state.nota1, state.nota2, state.nota3, state.nota4, state.nota5],
                                        backgroundColor: [
                                            "#20d077",
                                            "#007be5",
                                            "#f9c851",
                                            "#ff6400",
                                            "#EF6262"
                                        ],
                                       
                                    }]
                            }
                            this.setState(state)
                        })
                       



                    //console.log(change.doc.id)
                  });
                  

                }, err => {
                    console.log(`Encountered error: ${err}`);
                });


    }
    
    render() {
        return (
            
                    <div className="p-col-12 p-lg-6">
                       <div className="card">
                           <h1 className="centerText">{this.props.nome}</h1>
                           <Chart type="pie" data={this.state.pieData} height="150"/>
                       </div>
                    </div>
             
        );
    }
}