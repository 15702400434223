import React, {useRef,useState,useEffect} from 'react';
import {withRouter} from "react-router-dom";
import ApiPitStop from '../service/apiPitStop';
import {Button} from 'primereact/button';
import { Messages } from 'primereact/messages';
import { DataTable } from 'primereact/datatable';
import {Column} from 'primereact/column';


const ExcelCRM = () => {

    const message = useRef();
    const [refreshInterval, setRefreshInterval] = useState(5000);
    const [dataTable, setDataTable] = useState(null);
    const [selectedResultados, setSelectedResultados] = useState(null);
    const dt = useRef(null);

    async function loadDataTable(){
        const service = new ApiPitStop();
        const {data} = await service.getFilesCRM()
        setDataTable(data)
        return data  
    }

    useEffect(() => {
        loadDataTable()
    },[]);

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(loadDataTable, refreshInterval);
            return () => clearInterval(interval);
        }        
    }, [refreshInterval]);


    async function GenerateExcel(){
        const service = new ApiPitStop();
        const {data} = await service.postFilesCRM()
        if(data.status==='success'){
            loadDataTable()
        }else{
            message.current.show({ severity: 'error', summary: 'Error ao Gerar Excel' });
        }
        return data  
    }


    const Download = (data) =>{
        async function link(){      
            const service = new ApiPitStop();
            const res = await service.postLinkFileCRM(data)
            if(res.data.status==='success'){
                window.location = res.data.url
            }else{
                message.current.show({ severity: 'error', summary: 'Error ao Download' });
            }
            return res
        }  
        link()   
    }



    const Created_at = (rowData) => {
        const date = new Date(rowData.created_date.split(" ", 1)).toLocaleDateString('pt-br',{timeZone: 'UTC'});
        const time = rowData.created_date.split(" ", 2);
        return (
            <>       
                {date+" - "+time[1].split(".", 1)}
            </>
        );
    }
      
    const action = (rowData) => {
        return (
            <div className="actions">                
               <Button label="Download" className="p-button-warning p-button-raised p-button-info p-mr-2 p-mb-1" onClick={()=>Download(rowData)}/> 
            </div>
        );
    }


    return (     
        <div className="login-body">
            <div className="card" style={{width: '50%' }}> 
                <div className="p-col" >
                    <Messages ref={message}/>
                </div>      
                <div style={{paddingBottom:'0.3rem'}}>
                    <Button label="Gerar Excel" className="p-mr-2 p-mb-1" onClick={()=>GenerateExcel()}/>   
                </div>

                <DataTable ref={dt} value={dataTable} selection={selectedResultados} onSelectionChange={(e) => setSelectedResultados(e.value)}
                    dataKey="id" paginator rows={10} className="datatable-responsive"
                    paginatorTemplate=" PrevPageLink PageLinks NextPageLink "
                    currentPageReportTemplate=""
                    emptyMessage="" header="">                       
                    <Column field="created_at" header="Criado em :" sortable body={Created_at}></Column> 
                    <Column body={action}></Column>                   
                </DataTable>
            </div>
        </div>
    );
}



export default  withRouter(ExcelCRM)