import React, { Component } from 'react';
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import AppMenu from './AppMenu';

import AppProfile from './AppProfile';
import { withRouter, Route } from "react-router-dom";
import Grafico from './components/Grafico';
import Home from './components/Home';
import Pesquisa from './components/Pesquisa';
import { Charts } from './components/Charts';
import FCA from './components/FCA';
import ExcelOnboarding from './components/ExcelOnboarding';
import SendContact from './components/SendContact';
import ExcelCRM from './components/ExcelCRM';
import { setUrl } from './service/url';
import { Excel } from './pages'


import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';


class Principal extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            url: 'https://app.powerbi.com/view?r=',
            uri: ''
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.createMenu();

        this._getGraficos = this._getGraficos.bind(this);
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }

        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    _getGraficos = (e) => {
        this.props.history.push('/grafico');
        setUrl(e.item.value)
    }



    createMenu() {
        this.menu = [
            { label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/home' },

            {
                label: 'Operações', icon: 'pi',
                items: [
                    {
                        label: 'Power BI',
                        icon: 'pi pi-fw pi-chart-line',
                        value: 'eyJrIjoiNDVjNGE2NDEtOGJmMC00OGE4LWIxOWQtNTVlMmYxMzBlY2NlIiwidCI6ImE1MTJmNmZiLTYxOGEtNDI3Yi1hMTM2LTI4Zjc5ZTg2ZGZiMSJ9&pageName=ReportSection', command: this._getGraficos,
                    },
                    {
                        label: 'Excel',
                        icon: 'pi pi-file-excel',
                        to: '/excel',
                    },

                ]
            },

            // {
            //     label: 'Onboarding', icon: 'pi',
            //     items: [
            //         {
            //             label: 'Power BI',
            //             icon: 'pi pi-fw pi-chart-line',
            //             value: 'eyJrIjoiYjQyNWM5MWItZDYzYS00YmFiLTljYTUtOWI1MWFkNjc3MjA1IiwidCI6ImE1MTJmNmZiLTYxOGEtNDI3Yi1hMTM2LTI4Zjc5ZTg2ZGZiMSJ9', command: this._getGraficos,
            //         },
            //         {
            //             label: 'Excel',
            //             icon: 'pi pi-file-excel',
            //             to: '/excelOnboarding',
            //         },

            //     ]
            // },

            {
                label: 'CRM', icon: 'pi',
                items: [
                    {
                        label: ' Envio',
                        icon: 'pi pi-fw pi-send',
                        to: '/sendContact',
                    },
                    {
                        label: 'Excel',
                        icon: 'pi pi-file-excel',
                        to: '/excelCRM',
                    },
                ]
            },
        ];
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {
        const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/direct_consumer_ambev.jpeg' : 'assets/layout/images/direct_consumer_ambev.jpeg';

        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <AppTopbar onToggleMenu={this.onToggleMenu} />

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick} style={{ overflow: 'hidden' }} >
                    <div className="layout-logo" >
                        <img style={{ width: "150px", height: "150px", borderRadius: "10px", backgroundColor: "white" }} alt="Logo" src={logo} />
                    </div>
                    <AppProfile />
                    <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                </div>

                <div className="layout-main">
                    <Route path="/grafico" exact component={Grafico} />
                    <Route path="/pesquisa" exact component={Pesquisa} />
                    <Route path="/home" exact component={Home} />
                    <Route path="/excel" exact component={Excel} />
                    <Route path="/excelOnboarding" exact component={ExcelOnboarding} />
                    <Route path="/charts" exact component={Charts} />
                    <Route path="/fca" exact component={FCA} />
                    <Route path="/sendContact" exact component={SendContact} />
                    <Route path="/excelCRM" exact component={ExcelCRM} />
                </div>

                <AppFooter />

                <div className="layout-mask"></div>
            </div>
        );
    }
}

export default withRouter(Principal);
