import React, { useState, useRef } from 'react';
import './SendContact.css';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import FileExample from '../midia/file/ExampleFile_CRM.xlsx';
import Service from '../service/apiPitStop';


const SendContact = () => {

    const message = useRef();
    const [file, setFile] = useState(null);
    const [disabledSend, setDisabledSend] = useState(true);
    const [disabledOpenFile, setDisabledOpenFile] = useState(false);
    const [disabledUpdate, setDisabledUpdate] = useState(true);
    const [disabledDelete, setDisabledDelete] = useState(true);
    const [startLoad, setStartLoad] = useState(false);
    const [value, setValue] = useState(0);

    const [description, setDescription] = useState(null);
    const [descriptionError, setDescriptionError] = useState(false);

    const dt = useRef(null);
    const [selected, setSelected] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [tableValue, setTableValue] = useState(null);


    const Openfolder = () => {
        if (description !== null) {
            setDescriptionError(false)
            var inputFile = document.getElementById('files')
            inputFile.click()
        } else {
            message.current.show({ severity: 'error', summary: 'Por favor ! Coloque uma descrição' });
            setDescriptionError(true)
        }

    }


    const Start = () => {
        setValue(0)
        let x = 0
        setStartLoad(true)
        setDisabledSend(true)
        var counterms = setInterval(function () {
            if (x <= 14) {
                x = x + 1
                let y = x * 100
                let z = y / 15
                setValue(z.toFixed())
            } else {
                clearInterval(counterms);
                x = 0
                setFile(null)
                setTableValue(null)
                setDisabledOpenFile(false)
                setDisabledUpdate(true)
                setDisabledDelete(true)
                setDisabledSend(true)
                setStartLoad(false)
                setDescriptionError(false)
                setDescription('')
                setDescription(null)
                message.current.show({ severity: 'success', summary: 'Mensagem enviada com Sucesso' });

            }
        }, 1000);
    }

    const SendMsg = () => {
        for (var i in tableValue) {
            async function send() {
                const envio = new Service();
                const res = await envio.postMessage(
                    tableValue[i].telefone,
                    tableValue[i].name,
                    tableValue[i].ambev_key,
                    tableValue[i].id_batch
                )
                return res
            }
            send();
        }
        Start()
    }

    const DeleteFile = () => {
        setFile(null)
        setTableValue(null)
        setDisabledOpenFile(false)
        setDisabledUpdate(true)
        setDisabledDelete(true)
        setDisabledSend(true)
    }


    const UploadFile = async () => {
        if (description !== null) {
            setDescriptionError(false)
            const service = new Service();
            const data = await service.postUploadFileExecl(file, description)
            if (data.data.status === 'success') {
                setTableValue(data.data.result)
                setDisabledOpenFile(true)
                setDisabledUpdate(true)
                setDisabledDelete(false)
                setDisabledSend(false)
            } else {
                setFile(null)
                setTableValue(null)
                setDisabledOpenFile(false)
                setDisabledUpdate(true)
                setDisabledDelete(true)
                setDisabledSend(true)
                message.current.show({ severity: 'error', summary: 'UploadFile erro' });
            }
            return data
        } else {
            message.current.show({ severity: 'error', summary: 'Por favor ! Coloque uma descrição' });
            setDescriptionError(true)
        }

    }

    const FileSelect = (e) => {
        e.preventDefault();
        var files = e.target.files
        if (files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(files[0])
            setDisabledUpdate(false)
        } else {
            message.current.show({ severity: 'error', summary: 'Tipo de arquivo não suportado' });
        }
    }

    const ambev_key = (rowData) => {
        return (
            <>
                {
                    rowData.ambev_key
                }
            </>
        );
    }


    const name = (rowData) => {
        return (
            <>
                {
                    rowData.name
                }
            </>
        );
    }
    const telefone = (rowData) => {
        return (
            <>
                {
                    rowData.telefone
                }
            </>
        );
    }

    return (
        <>
            <div className="card">

                <div className="p-col" >
                    <a href={FileExample} download="ArquivoDeExemplo">
                        <Button icon="pi pi-file-excel" label="Arquivo de Exemplo" className="p-button-secondary" style={{ backgroundColor: '#c8c8c8' }} />
                    </a>
                </div>
                <div className="p-col" >
                    <InputText placeholder="Descrição" Type="text" value={description} onChange={(e) => setDescription(e.target.value)} style={{ borderColor: descriptionError ? 'red' : '', width: '40rem' }} />
                </div>

                <div className="p-inputgroup p-md-12">

                    <span>
                        {!file &&
                            <input type='file' accept=".xlsx" id='files' multiple={false} onChange={FileSelect} />
                        }
                        {!disabledOpenFile &&
                            <>
                                <Button icon="pi pi-folder-open" onClick={Openfolder} disabled={disabledOpenFile} />
                            </>
                        }
                        {!disabledDelete &&
                            <>
                                <Button icon="pi pi-trash" className="p-button-danger" onClick={DeleteFile} disabled={disabledDelete} />
                            </>
                        }
                    </span>

                    <span className="paddingLeft">
                        {disabledSend &&
                            <>
                                <Button icon="pi pi-cloud-upload" className="p-button-warning" onClick={UploadFile} disabled={disabledUpdate} />
                            </>
                        }
                        {!disabledSend &&
                            <>
                                <Button icon="pi pi-send" className="p-button-success" onClick={SendMsg} disabled={disabledSend} />
                            </>
                        }
                    </span>

                    {startLoad
                        ? <div className="p-col" >
                            <ProgressBar value={value} />
                        </div>
                        : <></>
                    }


                </div>


                <div className="p-col" >
                    <Messages ref={message} />
                </div>



                {tableValue &&
                    <div className="card">

                        <DataTable ref={dt} value={tableValue} selection={selected} onSelectionChange={(e) => setSelected(e.value)}
                            dataKey="id" paginator rows={10} className="datatable-responsive p-datatable-sm"
                            paginatorTemplate=" PrevPageLink PageLinks NextPageLink "
                            currentPageReportTemplate=""
                            globalFilter={globalFilter} emptyMessage="">
                            <Column field="ambev_key" header="Chave" sortable body={ambev_key}></Column>
                            <Column field="name" header="Nome" sortable body={name}></Column>
                            <Column field="telefone" header="Telefone" sortable body={telefone}></Column>
                        </DataTable>
                    </div>
                }

            </div>
        </>
    );
}

export default SendContact;