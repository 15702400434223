import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import firebase from '../service/firebase';
import {Panel} from 'primereact/panel';
import {Button} from 'primereact/button';
import { Nota } from './Nota';

class Home extends Component {


    render() {

        return (
            <div className="p-grid p-fluid dashboard">
            

            </div>

        )
    
          
    }
}

export default  withRouter(Home)