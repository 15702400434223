import React, { Component } from 'react';
import './styled.css';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ReactExport from "react-data-export";
import ApiPitStop from '../../service/apiPitStop';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export class Excel extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            dt_ini: moment(`${new Date().getMonth()}-${new Date().getDate()}-${new Date().getFullYear()}`).format('YYYY-MM-DD'),
            dt_fim: moment(`${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`).format('YYYY-MM-DD'),
            load: true,
        }
        this.exportCSV = this.exportCSV.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }
    async onSearch() {
        this.setState({ dataTableValue: [] });
        const apiPitStop = new ApiPitStop();
        try {
            const { data: { pesquisas } } = await apiPitStop.postPesquisa(
                moment(this.state.dt_ini).format('DD/MM/YYYY'),
                moment(this.state.dt_fim).format('DD/MM/YYYY')
            );
            if (!pesquisas) return console.log(`Error-- `);
            this.setState({ dataTableValue: pesquisas })
        } catch (error) {
            console.log(error);
        }
    }
    componentDidMount() {
        this.onSearch();
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }
    ExportCSV = () => {
        const dt = this.state.dataTableValue;
        const fileName = 'pesquisas';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const exportToCSV = (dt, fileName) => {
            const ws = XLSX.utils.json_to_sheet(dt);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    }
    render() {
        const headerDataTable = () => {
            return (
                <div className='actions--'>
                    <ExcelFile
                        element={
                            <Button
                                type="button"
                                icon="pi pi-file-excel"
                                label="Excel"
                            />
                        }
                        filename="pesquisasatisfacao"
                    >
                        <ExcelSheet data={this.state.dataTableValue} name="pesquisa">
                            <ExcelColumn label="Data" value="Data"/>
                            <ExcelColumn label="Programa" value="Programa" />
                            <ExcelColumn label="Código Unidade" value="Código Unidade" />
                            <ExcelColumn label="Unidade" value="Unidade" />
                            <ExcelColumn label="Questionario" value="Questionario" />
                            <ExcelColumn label="Consultor" value="Consultor" />
                            <ExcelColumn label="Franqueado" value="Franqueado" />
                            <ExcelColumn label="Telefone" value="Telefone" />
                            <ExcelColumn label="Nota" value="Nota" />
                            <ExcelColumn label="Sugestão / Crítica" value="Sugestão / Crítica" />
                            <ExcelColumn label="Tipo Resposta" value="Tipo Resposta" />
                            <ExcelColumn label="Estado" value="Estado" />
                        </ExcelSheet>
                    </ExcelFile>
                    <Button
                        type="button"
                        icon="pi pi-external-link"
                        label=".csv"
                        onClick={this.exportCSV}
                    />
                </div>
            )
        }
        return (
            <div className="p-grid content">
                <div className="p-col-12">
                    <div className="card--">
                        <span> De </span>
                        <input
                            type="date"
                            id="dt_ini"
                            name="dt_ini"
                            value={this.state.dt_ini}
                            onChange={(e) => this.setState({ dt_ini: e.target.value })}
                        />
                        <span> a </span>
                        <input
                            type="date"
                            id="dt_fim"
                            name="dt_fim"
                            value={this.state.dt_fim}
                            onChange={(e) => this.setState({ dt_fim: e.target.value })}
                        />
                        <div>
                            <button className='btn-search'
                                onClick={() => this.onSearch()}
                            >
                                Pesquisar
                            </button>
                        </div>
                    </div>
                    {this.state.dataTableValue?.length > 0 &&
                        (<div className="card box--dataTable">
                            <h1>Pesquisas de Satisfação</h1>
                            <DataTable
                                value={this.state.dataTableValue}
                                paginatorPosition="both"
                                ref={(el) => { this.dt = el }}
                                scrollable selectionMode="single"
                                header={headerDataTable()}
                                paginator={true}
                                rows={10}
                                responsive={true}
                                selection={this.state.dataTableSelection}
                                onSelectionChange={
                                    (event) => this.setState(
                                        { dataTableSelection: event.value }
                                    )
                                }>
                                <Column field="Data" header="Data" sortable={true} />
                                <Column field="Programa" header="Programa" sortable={true} />
                                <Column field="Código Unidade" header="Código Unidade" sortable={true} />
                                <Column field="Unidade" header="Unidade" sortable={true} />
                                <Column field="Questionario" header="Questionario" sortable={true} />
                                <Column field="Consultor" header="Consultor" sortable={true} />
                                <Column field="Franqueado" header="Franqueado" sortable={true} />
                                <Column field="Telefone" header="Telefone" sortable={true} />
                                <Column field="Nota" header="Nota" sortable={true} />
                                <Column field="Sugestão / Crítica" header="Sugestão / Crítica" sortable={true} />
                                <Column field="Tipo Resposta" header="Tipo Resposta" sortable={true} />
                                <Column field="Estado" header="Estado" sortable={true} />
                            </DataTable>
                        </div>)
                    }
                </div>
            </div>
        )
    }
}
