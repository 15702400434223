import axios from 'axios';

export default class apiPitStop {

    postDataList() {
        return axios.post('https://prod.ambevapi.mi4u.app/api/RetornaLista').then((res) => res.data.Registros);
    }

    download(data) {
        return axios.post('https://prod.ambevapi.mi4u.app/api/GeraLink', data).then((res) => res.data);
    }

    postUploadFileExecl(file, description) {
        const data = new FormData()
        data.append('arquivo', file)
        return axios.post('https://ambevcrm.mi4u.app/api/UploadToGcp?bucket=ambev-mi4u-crm&description=' + description, data)
    }

    postMessage(phone, name, ambev_key, id_batch) {
        return axios.post(`https://ambevbrahma.mi4u.app/v1/cadastrarnps?chave=${ambev_key}&nome=${name}&telefone=55${phone}&token=KevwyTpb0BsdhsxOLDSsD5RjzHG`);
    }

    getFilesCRM() {
        return axios.get('https://ambevcrm.mi4u.app/api/Files')
    }

    postFilesCRM() {
        //return axios.post('https://ambevcrm.mi4u.app/api/Files')
        return axios.post('https://prod-ambev-crm-i5aprdk53a-uc.a.run.app/api/Files')

    }

    postLinkFileCRM(dataFile) {
        let data = {
            bucket_name: dataFile.bucket_name,
            filename: dataFile.filename,
        }
        return axios.post('https://ambevcrm.mi4u.app/api/LinkFiles', data)
    }

    async postPesquisa(ini, fim) {
        try {
            return await axios.post(
                `https://ambev-pitstop-api-i5aprdk53a-uc.a.run.app/v1/pesquisas?dt_ini=${ini.split("/")[0]}%2F${ini.split("/")[1]}%2F${ini.split("/")[2]}&dt_fim=${fim.split("/")[0]}%2F${fim.split("/")[1]}%2F${fim.split("/")[2]}`)
        } catch (error) {
            return false
        }
    }

}