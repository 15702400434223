import firebase from 'firebase';
const config = {
    apiKey: "AIzaSyDyB_d4Ko4gHHH-KkB_0TDnQU0bVFdPqYw",
    authDomain: "ambev-pitstop-mklo.firebaseapp.com",
    projectId: "ambev-pitstop-mklo",
    storageBucket: "ambev-pitstop-mklo.appspot.com",
    messagingSenderId: "729426870184",
    appId: "1:729426870184:web:46baf1c49ddf45a53c279d",
    measurementId: "G-B54PBCSYK7"
} 

firebase.initializeApp(config);
export default firebase