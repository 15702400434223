import React, { Component } from 'react';
import classNames from 'classnames';
import {logout, getUser} from "./service/auth"  
import {withRouter} from "react-router-dom";

class AppProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false,
            usuario: ""
        };
        this.onClick = this.onClick.bind(this);
        this._sair = this._sair.bind(this);
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    _sair = () => {
        logout();
        this.props.history.push("/");
   }

    componentDidMount(){
        let usuario = getUser();
        
        if(usuario == "admin"){
            this.setState({usuario : "Admin"})
        }else if(usuario == "rodrigo.faccioli"){
            this.setState({usuario : "Rodrigo Faccioli"})
        }


        

    }

    render() {
        return  (
            <div className="layout-profile">
                <button className="p-link layout-profile-link" onClick={this.onClick}>
                    <span className="username">{this.state.usuario}</span>
                    <i className="pi pi-fw pi-cog"/>
                </button>
                <ul className={classNames({'layout-profile-expanded': this.state.expanded})}>
                    <li><button onClick={this._sair}  className="p-link"><i className="pi pi-fw pi-power-off"/><span>Sair</span></button></li>
                </ul>
            </div>
        );
    }
}

export default withRouter(AppProfile)