import React, {Component} from 'react';
import {Panel} from 'primereact/panel';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {login, setUser} from "./service/auth"
import {withRouter} from "react-router-dom";

class Login extends Component {

     constructor(props) {
       super(props)
     
       this.state = {
           usuario: "",
           senha: "",
           error:""
          
       }
     }
     


    _login = () => {
        const { usuario, senha } = this.state;
        if (!usuario || !senha) {
            this.setState({ error: "Preencha usuário e senha para continuar!" });
            console.log(this.state.error)
            
        } else {
            if(usuario === "admin" & senha === "@mbev2024"){
                login("fsdlhoywhoowey73-700016970703;lllhlys=fhrknrudmnyjdik")
                setUser(usuario)
                this.props.history.push("/home");
            }
            
            else if(usuario === "rodrigo.faccioli" & senha === "123456"){
                login("fsdlhoywhoowey73-700016970703;yslhyis,nuyiss")
                setUser(usuario)
                this.props.history.push("/home");
            }
            


            else{
                this.setState({ error: "Usuário ou senha estão incorretos. Verifique!" });
                
            }
            
        }

    }

    render() {
        const logo = 'assets/layout/images/direct_consumer_ambev.jpeg';
        return (
            <div className="login">

            <div className="cardmaster">
              


                <div className="cardlogin">
                        <div className="layout-logo">
                                <img style={{width: "150px", height: "150px", borderRadius: "10px", backgroundColor: "white"}} alt="Logo" src={logo} />
                        </div>

                                        
                        <h1>{this.state.error}</h1>
                        <div className="p-col-12  inptextlogin" >
                            <InputText placeholder="Usuário" onChange={e => this.setState({ usuario: e.target.value, error: "" })}/>
                        </div>
                        <div className="p-col-12  inptextlogin">
                            <InputText placeholder="Senha" Type="password" onChange={e => this.setState({ senha: e.target.value , error: "" })}/>
                        </div>

                        <div className="p-col-12 buttonlogin">
                        <Button label="Entrar" onClick={this._login} style={{marginBottom:'30px', width: '150px', fontWeight: 'bold', backgroundColor: '#20409a'}} />
                        </div>

                </div>


            </div>

          
        </div>

     
        );
    }
}
export default withRouter(Login);