import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import axios from 'axios';

class Pesquisa extends Component {

    constructor() {
        super();
        this.state = {
            whatsapp: '',
            consultor: '',
            franqueado: '',
            datavisita: ''
        }

        this._salvar = this._salvar.bind(this)
    }


    _salvar = () => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
          };

        console.log(this.state)
          
        let satisfacao = {
            phone_number: "55"+this.state.whatsapp,
            consultor: this.state.consultor,
            franqueado: this.state.franqueado,
            data_hora_visita: this.state.datavisita
          };

        console.log(satisfacao)

        axios.post(`https://apibotambev.mi4u.com.br/api/createSatisfaction`,  satisfacao , axiosConfig)
          .then(res => {
            this.setState({ whatsapp:'', consultor:'', franqueado: '', datavisita: ''})
            console.log(res);
            console.log(res.data);
          })


       
    }


    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                <div className="p-grid p-fluid">
                    <div className="card card-w-title">
                        <h1>Pesquisa de Satisfação</h1>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4">
                                <InputText placeholder="Whatsapp" value={this.state.whatsapp} onChange={e => this.setState({ whatsapp: e.target.value})}/>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <InputText placeholder="Consultor" value={this.state.consultor} onChange={e => this.setState({ consultor: e.target.value})}/>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <InputText placeholder="Franqueado" value={this.state.franqueado} onChange={e => this.setState({ franqueado: e.target.value})}/>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <InputText placeholder="Data e hora da visita" value={this.state.datavisita} onChange={e => this.setState({ datavisita: e.target.value})} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                
                            </div>
                            <div className="p-col-12 p-md-4">
                                
                            </div>
                            <div className="p-col-12 p-md-4">
                                <Button label="Salvar" onClick={this._salvar} style={{marginBottom:'30px', width: '150px', fontWeight: 'bold', backgroundColor: '#20409a'}} />
                            </div>
                           
                        </div>
                    </div>
                  
                    </div>

                </div>
            </div>
        );
    }
}

export default  withRouter(Pesquisa)