import React, {useRef,useState,useEffect} from 'react';
import {withRouter} from "react-router-dom";

import ApiPitStop from '../service/apiPitStop';
import {Button} from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import {Column} from 'primereact/column';


const ExcelOnboarding = () => {

    const [dataTable, setDataTable] = useState(null);
    const [selectedResultados, setSelectedResultados] = useState(null);
    const dt = useRef(null);

    useEffect(() => {
        const apiPitStop = new ApiPitStop();
        apiPitStop.postDataList().then(data => setDataTable(data));
    }, []);

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-mt-2"></h5>
        </div>
    );

    const Created_at = (rowData) => {
        return (
            <>       
                {rowData.created_at}
            </>
        );
    }

    const Download=(id)=>{
 
        async function UrlDownload(){      
            let data =
            {
                ID:id
            }
            const apiPitStop = new ApiPitStop();
            apiPitStop.download(data).then(data => {           
                if(data.status==='success'){
                    window.location = data['Generated GET signed URL:']
                }else{
                    console.log('error')
                }
            })

        }     
        UrlDownload();   
      

    }

      
    const action = (rowData) => {
        return (
            <div className="actions">                
               <Button label="Download" className="p-button-warning p-button-raised p-button-info p-mr-2 p-mb-1" onClick={()=>Download(rowData.id)}/> 
            </div>
        );
    }


    return (     
        <div className="login-body">
            <div className="card" style={{width: '50%' }} >  
            <div className="card card-w-title">
                <h1>Resultados Onboarding - Excel </h1>                
                <DataTable ref={dt} value={dataTable} selection={selectedResultados} onSelectionChange={(e) => setSelectedResultados(e.value)}
                    dataKey="id" paginator rows={10} className="datatable-responsive"
                    paginatorTemplate=" PrevPageLink PageLinks NextPageLink "
                    currentPageReportTemplate=""
                    emptyMessage="" header={header}>                       
                    <Column field="created_at" header="Criado em :" sortable body={Created_at}></Column> 
                    <Column body={action}></Column>                   
                </DataTable>
            </div>
            </div>
        </div>
    );
}



export default  withRouter(ExcelOnboarding)