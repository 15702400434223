import React, {Component} from 'react';
import {withRouter} from "react-router-dom";


class FCA extends Component {


    render() {

        return (
            <div className="p-grid p-fluid ">
               
               <iframe width="100%" height="800px" frameBorder="0" src="https://docs.google.com/spreadsheets/d/1dTZQuCWEQSg9DD6mEgD9NKZhFFUm9_ol-KrYKDII61A/edit#gid=0"/>

            </div>

        )
    
          
    }
}

export default  withRouter(FCA)