import React, {Component} from 'react';
import {Chart} from 'primereact/chart';
import firebase from '../service/firebase';
import { PieConsultor } from './PieConsultor';

export class Charts extends Component {
     
    constructor(){
        super();
        this.state = {
            consultores : [],
            pieData: {
                labels: ['1','2','3','4','5'],
                datasets: [
                    {
                        data: [15, 5, 5, 5, 5],
                        backgroundColor: [
                            "#20d077",
                            "#007be5",
                            "#f9c851",
                            "#ff6400",
                            "#EF6262"
                        ],
                       
                    }]
            },
        }
    }


    componentDidMount(){
        const db = firebase.firestore();
        let consultores = new Set()
        let query = db.collection('session').where('closed', '==', 'S').where('cancelado', '==', 'N');
        let observer = query.onSnapshot(querySnapshot => {
                console.log(`Received query snapshot of size ${querySnapshot.size}`);
                querySnapshot.docChanges().forEach(change => {
                    let satisfacoes = db.collection('satisfacao').where('session', '==', change.doc.id).orderBy('ordem')
                        .get()
                        .then(s => {
                            s.forEach(sat =>{
                                consultores.add(sat.data().consultor)
                                let state = this.state
                                state.consultores = [...consultores]
                                this.setState(state)
        
                            })
                        }
                        
                        )
                       



                    //console.log(change.doc.id)
                  });
                  

                }, err => {
                    console.log(`Encountered error: ${err}`);
                });


    }
    
    render() {
        return (
            <div className="p-grid p-fluid">

                {this.state.consultores.map(m => 
                    <PieConsultor nome={m}/>
                   )

                }
         
            </div>
        );
    }
}