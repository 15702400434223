export const TOKEN_KEY = "@ucb-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_);
  
};

export const USER_ = "@ucb-user"
export const getUser = () => localStorage.getItem(USER_);
export const setUser = user => {
    localStorage.setItem(USER_, user)
}

