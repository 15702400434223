import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {getUrl} from '../service/url'

class Grafico extends Component {

    constructor() {
        super();
        this.state = {
            url: '',
            uri: ''
        };
    }



    render() {
  


        return (
            <div className="grafico">
                   
                    <iframe width="100%" height="100%" frameBorder="0" src={'https://app.powerbi.com/view?r='+getUrl()}/>
                
            </div>
        );
    }
}

export default withRouter(Grafico)